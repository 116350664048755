<!-- Settings -->
<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
      <!--begin: Settings-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Settings Body-->
        <div class="row justify-content-center my-10 px-8 px-lg-10">
          <div class="col-xl-12 col-xxl-10 invoicepage">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form printSection">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <el-row>
                  <el-col :span="24" align="right">
                    <el-dropdown
                      @command="handleActions($event)"
                      size="medium"
                      split-button
                      class="action_btn"
                    >
                      Actions
                      <el-dropdown-menu slot="dropdown" class="action_dropdown">
                        <el-dropdown-item :command="1">Print</el-dropdown-item>
                        <el-dropdown-item :command="2"
                          >Download</el-dropdown-item
                        >
                        <el-dropdown-item :command="3"
                          >Send Email</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" align="left" v-if="view.website_details">
                    <span
                      v-loadimage="view.website_details.website_logo"
                    ></span>
                  </el-col>
                  <el-col :span="12" align="right">
                    <b>
                      <label class="mt-10"
                        >{{
                          view.website_details
                            ? view.website_details.address
                            : ""
                        }},
                        {{
                          view.website_details
                            ? view.website_details.city_details.city_name
                            : ""
                        }},
                        <label
                          >{{
                            view.website_details
                              ? view.website_details.state_details.state_name +
                                " - "
                              : ""
                          }}{{
                            view.website_details
                              ? view.website_details.post_code
                              : ""
                          }},
                          {{
                            view.website_details
                              ? view.website_details.country
                              : ""
                          }}</label
                        > </label
                      ><br />

                      <label
                        >Phone:
                        {{
                          view.website_details ? view.website_details.phone : ""
                        }}</label
                      ><br />
                      <label
                        >Fax:
                        {{
                          view.website_details ? view.website_details.fax : ""
                        }}</label
                      ><br />
                      <label
                        >Email:
                        {{
                          view.website_details
                            ? view.website_details.email_address
                            : ""
                        }}</label
                      >
                    </b>
                    <br />
                  </el-col>
                </el-row>
                <hr class="invoice-line" />
                <el-row>
                  <el-col :span="24" align="center">
                    <h2 style="font-family: Arial; text-align: center">
                      <b>ORDER CONFIRMATION</b>
                    </h2>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 5px; margin-bottom: 5px">
                  <el-col :span="12" align="left">
                    <span><b>ORDER # : </b></span
                    ><span
                      ><b> {{ view.order_number }}</b></span
                    >
                  </el-col>
                  <el-col :span="12" align="right">
                    <span><b>CUSTOMER ID : </b></span
                    ><span
                      ><b>{{
                        view.customer ? view.customer.customer_id : ""
                      }}</b></span
                    >
                  </el-col>
                  <el-col :span="12" align="left">
                    <span><b>ORDER DATE : </b></span
                    ><span
                      ><b> {{ format_date(view.created_date) }}</b></span
                    >
                  </el-col>
                </el-row>
                <el-row class="add-info">
                  <el-col :span="8">
                    <div class="billing-adr" style="padding-left: 10px">
                      BILL TO :
                    </div>
                    <div v-if="view.billing_address_order">
                      <div style="padding: 3px" v-if="view.customer">
                        <span v-if="view.customer.company_name">{{
                          view.customer.company_name
                        }}</span>
                       
                      </div>
                      <div style="padding: 3px" v-if="view.customer">
                        <span v-if="view.customer.first_name">{{
                          view.customer.first_name
                        }}</span>
                        <span v-if="view.customer.last_name">{{
                          view.customer.last_name
                        }}</span>
                      </div>
                      <div
                        style="padding: 3px"
                        v-if="view.billing_address_order.address_line_2"
                      >
                        <span
                          v-if="view.billing_address_order.address_line_2"
                          >{{ view.billing_address_order.address_line_2 }}</span
                        ><span v-if="view.billing_address_order.address_line_1"
                          >, Unit No :
                          {{ view.billing_address_order.address_line_1 }}</span
                        >
                      </div>
                      <div
                        style="padding: 3px"
                        v-if="
                          view.billing_address_order.city_details &&
                          view.billing_address_order.city_details.city_name
                        "
                      >
                        {{ view.billing_address_order.city_details.city_name }}
                      </div>
                      <div
                        style="padding: 3px"
                        v-if="
                          view.billing_address_order.state_details &&
                          view.billing_address_order.state_details.state_name
                        "
                      >
                        {{
                          view.billing_address_order.state_details.state_name +
                          " - " +
                          view.billing_address_order.post_code
                        }}
                      </div>
                    </div>
                  </el-col>
                  <el-col
                    :span="8"
                    align="left"
                    :offset="8"
                    v-if="view.shipping_address_order"
                  >
                    <div class="billing-adr" style="padding-left: 10px">
                      SHIP TO :
                    </div>
                    <div style="padding: 3px" v-if="view.customer">
                        <span v-if="view.customer.company_name">{{
                          view.customer.company_name
                        }}</span>
                       
                      </div>
                    <div style="padding: 3px" v-if="view.customer">
                      {{
                        view.customer
                          ? view.customer.first_name +
                            " " +
                            (view.customer.last_name == null
                              ? ""
                              : view.customer.last_name)
                          : ""
                      }}
                    </div>
                    <div
                      style="padding: 3px"
                      v-if="view.shipping_address_order.address_line_2"
                    >
                      <span v-if="view.shipping_address_order.address_line_2">{{
                        view.shipping_address_order.address_line_2
                      }}</span
                      ><span v-if="view.shipping_address_order.address_line_1"
                        >, Unit No :
                        {{ view.shipping_address_order.address_line_1 }}</span
                      >
                    </div>
                    <div
                      style="padding: 3px"
                      v-if="
                        view.shipping_address_order.city_details &&
                        view.shipping_address_order.city_details.city_name
                      "
                    >
                      {{ view.shipping_address_order.city_details.city_name }}
                    </div>
                    <div
                      style="padding: 3px"
                      v-if="
                        view.shipping_address_order.state_details &&
                        view.shipping_address_order.state_details.state_name
                      "
                    >
                      {{
                        view.shipping_address_order.state_details.state_name +
                        " - " +
                        view.shipping_address_order.post_code
                      }}
                    </div>
                  </el-col>
                </el-row>
                <div class="row">
                  <div class="col-xl-12">
                    <table
                      search-options=""
                      id="website-table"
                      role="table"
                      aria-busy="false"
                      aria-colcount="14"
                      class="table b-table tab-row-hover"
                    >
                      <tbody role="rowgroup">
                        <tr class="billing-cl2 text-center invoice-header">
                          <th aria-colindex="2" role="cell">
                            <div>CUSTOMER P.O</div>
                          </th>
                          <th aria-colindex="2" role="cell">
                            <div>ORDER TAKEN BY</div>
                          </th>
                          <th aria-colindex="2" role="cell">
                            <div>ORDER PLACED BY</div>
                          </th>
                          <th aria-colindex="2" role="cell">
                            <div>SALESPERSON</div>
                          </th>
                          <th aria-colindex="2" role="cell">
                            <div>SHIP VIA</div>
                          </th>
                          <!-- <th aria-colindex="2" role="cell"><div >TERMS</div></th> -->
                          <th aria-colindex="1" role="cell">
                            <div>DELIVERY DATE</div>
                          </th>
                        </tr>
                        <tr class="billing-cl3">
                          <td aria-colindex="1" role="cell" class="text-center">
                            <div>{{ view.customer_po }}</div>
                          </td>
                          <td aria-colindex="2" role="cell" class="text-center">
                            <div>{{ view.order_taken_by }}</div>
                          </td>
                          <td aria-colindex="2" role="cell" class="text-center">
                            <div>{{ view.order_by }}</div>
                          </td>
                          <td aria-colindex="1" role="cell" class="text-center">
                            <div>{{ view.sale_person }}</div>
                          </td>
                          <td aria-colindex="2" role="cell" class="text-center">
                            <div>{{ view.ship_via }}</div>
                          </td>
                          <!-- <td aria-colindex="1" role="cell" class="text-center"><div>{{view.term}}</div></td> -->
                          <td aria-colindex="1" role="cell" class="text-center">
                            <div>{{ format_date(view.delivery_date) }}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      search-options=""
                      id="website-table"
                      role="table"
                      aria-busy="false"
                      aria-colcount="14"
                      class="table b-table tab-row-hover"
                      style="margin-top: 20px"
                    >
                      <tbody role="rowgroup">
                        <tr
                          class="billing-cl2 text-center"
                          style="background-color: #366092; color: #ffffff"
                        >
                          <th aria-colindex="1" role="cell">
                            <div>ITEM #</div>
                          </th>
                          <th aria-colindex="2" role="cell" >
                            <div>PRODUCT NAME</div>
                          </th>
                          <th aria-colindex="2" role="cell"><div>QTY</div></th>
                          <th aria-colindex="2" role="cell"><div>Type</div></th>
                          <th aria-colindex="2" role="cell">
                            <div>DISCOUNT</div>
                          </th>
                          <th aria-colindex="2" role="cell">
                            <div>PRICE</div>
                          </th>
                          <th aria-colindex="2" role="cell">
                            <div>AMOUNT</div>
                          </th>
                        </tr>
                        <tr
                          class="billing-cl3"
                          v-for="item in view.order_items"
                          :key="item.id"
                        >
                          <td aria-colindex="1" role="cell" class="text-center">
                            <div>
                              {{
                                item.product_item.sku
                                  ? item.product_item.sku
                                  : ""
                              }}
                            </div>
                          </td>
                          <td aria-colindex="1" role="cell" >
                            <div>{{ item.product_item.name }}</div>
                          </td>
                          <td aria-colindex="2" role="cell" class="text-center">
                            <div>{{ item.quantity }}</div>
                          </td>
                          <td aria-colindex="2" role="cell" class="text-center">
                            <div>{{ item.type }}</div>
                          </td>
                          <td aria-colindex="1" role="cell" class="text-center">
                            <div v-if="item.extra_discount">
                              {{ item.extra_discount }}%
                            </div>
                          </td>
                          <td
                            aria-colindex="2"
                            role="cell"
                            class="text-center"
                            v-if="item.unit_price"
                          >
                            <div>
                              ${{ parseFloat(item.unit_price).toFixed(2) }}
                            </div>
                          </td>
                          <td aria-colindex="1" role="cell" class="text-right pr-5">
                            <div>
                              ${{ parseFloat(item.total_price).toFixed(2) }}
                            </div>
                          </td>
                        </tr>
                        <tr class="billing-cl3">
                          <td aria-colindex="2" role="cell" colspan="5">
                            <div class="padding-10">
                              <b
                                ><u
                                  >Other Comments or Special Instructions</u
                                ></b
                              >
                            </div>
                            <div class="padding-10">{{ view.comments }}</div>
                            <div
                              class="padding-10"
                              v-if="view.pickup_person"
                            >Who is receiving order : <b>{{ view.pickup_person }}</b></div>
                            <div
                              class="padding-10"
                              style="margin-top: 40px"
                              v-if="view.discount"
                            >
                              <b>You save this order : </b>${{
                                parseFloat(view.discount).toFixed(2)
                              }}
                            </div>
                          </td>
                          <td aria-colindex="2" role="cell">
                            <div class="padding-10">Sub Total</div>
                            <div
                              class="padding-10"
                              v-if="view.inside_price_total"
                            >
                              Carry In Service
                            </div>
                            <div class="padding-10" v-if="view.credit_amount">
                              Credit Used
                            </div>
                            <!-- <div class="padding-10" v-if="view.ct_discount">Customer Discount</div> -->
                            <div class="padding-10" v-if="view.discount">
                              Saving
                            </div>
                            <div class="padding-10" v-if="view.shipping">
                              Shipping
                            </div>
                            <div class="padding-10" v-else>Shipping</div>
                            <div
                              class="padding-10"
                              v-if="view.adjustment_price"
                            >
                              Adjustment
                              <span>{{
                                view.adjustment_type == 1 ? "(+)" : "(-)"
                              }}</span>
                            </div>
                            <div class="padding-10">
                              HST({{ view.tax_per }}%)
                            </div>
                            <hr class="invoice-line" />
                            <div class="padding-10">Total</div>
                          </td>
                          <td aria-colindex="2" role="cell" class="text-center">
                            <div class="padding-10" style="text-align:right">
                              ${{ parseFloat(view.sub_total).toFixed(2) }}
                            </div>
                            <div
                              class="padding-10" style="text-align:right"
                              v-if="view.inside_price_total"
                            >
                              ${{
                                parseFloat(view.inside_price_total).toFixed(2)
                              }}
                            </div>
                            <div class="padding-10" style="text-align:right" v-if="view.credit_amount">
                              ${{ view.credit_amount }}
                            </div>
                            <!-- <div class="padding-10" v-if="view.ct_discount">${{view.ct_discount}}</div> -->
                            <div class="padding-10" style="text-align:right" v-if="view.discount">
                              <s>${{ parseFloat(view.discount).toFixed(2) }}</s>
                            </div>
                            <div class="padding-10" style="text-align:right" v-if="view.shipping">
                              ${{ parseFloat(view.shipping).toFixed(2) }}
                            </div>
                            <div class="padding-10" style="text-align:right" v-else>
                              <span v-if="view.shipping_manual"
                                >${{
                                  parseFloat(view.shipping_manual).toFixed(2)
                                }}</span
                              >
                              <span v-else>--</span>
                            </div>
                            <div
                              class="padding-10" style="text-align:right"
                              v-if="view.adjustment_price"
                            >
                              ${{
                                parseFloat(view.adjustment_price).toFixed(2)
                              }}
                            </div>
                            <div class="padding-10" style="text-align:right">
                              <span v-if="view.tax_total > 0">$</span>
                              <span v-if="view.tax_total > 0">{{
                                parseFloat(view.tax_total).toFixed(2)
                              }}</span>
                              <span v-else>--</span>
                            </div>
                            <hr class="invoice-line" />
                            <div class="padding-10" style="text-align:right">${{ view.total }}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <el-row>
                  <el-col :span="24" align="left">
                    <div style="text-align: center">
                      <b
                        >If you have any questions about this order, please
                        contact</b
                      >
                    </div>
                    <div style="text-align: center; font-size: 15px">
                      <b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="mt-3" v-if="view.cancel_order.length > 0">
                  <div style="font-size: 18px" class="mb-5">
                    <span><b>Order Status : </b></span>
                    <span>
                      <span
                        v-if="view.order_status == 'waiting_payment'"
                        class="red font-weight-bold"
                        >Waiting for Payment</span
                      >
                      <span
                        v-if="view.order_status == 'yet_verified'"
                        class="red font-weight-bold"
                        >Order Preparation</span
                      >
                      <span
                        v-if="view.order_status == 'pending'"
                        class="gray font-weight-bold"
                        >Pending</span
                      >
                      <span
                        v-if="view.order_status == 'processing'"
                        class="active"
                        >Processing</span
                      >
                      <span v-if="view.order_status == 'pickup'" class="active"
                        >Ready to Pick Up</span
                      >
                      <span v-if="view.order_status == 'on-hold'" class="gray"
                        >On Hold</span
                      >
                      <span
                        v-if="view.order_status == 'completed'"
                        class="active"
                        >Completed</span
                      >
                      <span v-if="view.order_status == 'cancelled'" class="red"
                        >Cancelled</span
                      >
                      <span v-if="view.order_status == 'refund'" class="red"
                        >Refunded</span
                      >
                      <span v-if="view.order_status == 'backorder'" class="red"
                        >Backorder</span
                      >
                      <span
                        v-if="view.order_status == 'partially_cancel'"
                        class="red"
                        >Partially Cancel</span
                      >
                      <span v-if="view.order_status == 'failed'" class="red"
                        >Failed</span
                      >
                      <span
                        v-if="view.order_status == 'waiting_dispatch'"
                        class="yellow"
                        >Waiting Dispatch</span
                      >
                      <span
                        v-if="view.order_status == 'dispatched'"
                        class="yellow"
                        >In Transit</span
                      >
                    </span>
                  </div>

                  <span
                    v-for="(canceOrder, k) in view.cancel_order"
                    :key="k"
                    class="mr-2"
                  >
                    <span v-if="canceOrder.type == 'refund'">Refund ID:</span>
                    <span v-else> Cancelled ID:</span>
                    <el-tag size="mini"
                      ><a href="#" @click.prevent="viewCancel(canceOrder.id)">{{
                        canceOrder.cancel_order_number
                      }}</a></el-tag
                    >
                  </span>
                </el-row>
                <div class="mt-5" v-if="view.delivery_order != ''">
                  <el-tabs class="root-tab" type="card" v-model="activeTab">
                    <el-tab-pane
                      label="DELIVERY DETAILS"
                      name="delivery_details"
                    >
                      <el-collapse
                        v-model="activeNames"
                        @change="handleChange"
                        v-for="(delivery, k) in view.delivery_order"
                        :key="k"
                      >
                        <el-collapse-item :name="k">
                          <template slot="title">
                            <div style="font-size: 15px">
                              <b>{{ k + 1 }} .Delivery </b>
                            </div>
                          </template>
                          <div class="flex-grow-1">
                            <div
                              class="
                                d-flex
                                justify-content-between
                                flex-wrap
                                mt-3
                              "
                            >
                              <div class="d-flex mr-3">
                                <span
                                  class="
                                    text-dark-75 text-hover-primary                                    
                                    mr-3
                                  "
                                  style="cursor: pointer"
                                  ><span class="font-size-h6 font-weight-bold">Delivery ID :</span>
                                  <el-tag
                                    slot="reference"
                                    size="mini"
                                    @click="viewDelivery(delivery.id)"
                                    >{{ delivery.id }}
                                  </el-tag></span
                                >
                                <span v-if="delivery.delivery_verified_by"
                                  ><i
                                    class="
                                      flaticon2-correct
                                      text-success
                                      font-size-h5
                                    "
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                flex-wrap
                                mt-1
                              "
                              v-if="delivery.edit_person_name"
                            >
                              <div class="d-flex mr-3">
                                <span
                                  class="
                                    text-dark-75 text-hover-primary
                                    mr-3
                                  "
                                  ><span class="font-size-h6 font-weight-bold">Order Verified By :</span>
                                  {{ delivery.edit_person_name }}</span
                                >
                              
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                flex-wrap
                                mt-3
                              "
                              v-if="delivery.delivery_verified_by"
                            >
                              <div class="d-flex mr-3" >
                                <span
                                  class="
                                    text-dark-75 text-hover-primary
                                    mr-3
                                  "
                                  ><span class="font-size-h6 font-weight-bold">Delivery Verified By :</span>
                                  {{ delivery.delivery_verified_by }}</span
                                >
                              
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                flex-wrap
                                mt-3
                              "
                              v-if="delivery.driver_name"
                            >
                              <div class="d-flex mr-3"  >
                                <span
                                  class="
                                    text-dark-75 text-hover-primary
                                    mr-3
                                  "
                                  ><span class="font-size-h6 font-weight-bold">Driver Name :</span>
                                  {{ delivery.driver_name }}</span
                                >
                              
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                flex-wrap
                                mt-3
                              "
                              v-if="delivery.comments"
                            >
                              <div class="d-flex mr-3" >
                                <span
                                  class="
                                    text-dark-75 text-hover-primary
                                    mr-3
                                  "
                                  ><span class="font-size-h6 font-weight-bold">Comments :</span>
                                  {{ delivery.comments }}</span
                                >
                                
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                flex-wrap
                                mt-3
                              "
                            >
                              <div
                                class="
                                  d-flex
                                  justify-content-between
                                  w-100
                                  my-3
                                "
                              >
                                <div
                                  class="
                                    align-self-center
                                    text-dark-75 text-hover-primary
                                    font-size-h5 font-weight-bold
                                  "
                                >
                                  <u>Delivery Document</u>
                                </div>
                                <div>
                                  <el-button
                                    type="primary"
                                    size="small"
                                    @click.prevent="
                                      addCompleteDocument(delivery.id)
                                    "
                                    >Add Document
                                  </el-button>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col-3"
                                v-for="(
                                  del_image, k
                                ) in delivery.delivery_document"
                                :key="k"
                              >
                                <div class="docs-images">
                                  <img
                                    class="cursor-pointer"
                                    :src="img_url + del_image.file_name"
                                  />
                                  <div
                                    class="d-flex justify-content-center pt-3"
                                  >
                                    <el-button
                                      @click="
                                        handleImageDownload(
                                          del_image.image_decode_url,
                                          del_image.file_name
                                        )
                                      "
                                      circle
                                      size="small"
                                      type="primary"
                                    >
                                      <i class="fa fa-download text-white"></i>
                                    </el-button>
                                    <el-button
                                      circle
                                      size="small"
                                      type="danger"
                                      @click="deleteDocumentById(del_image.id)"
                                    >
                                      <i class="fa fa-trash text-white"></i>
                                    </el-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-tab-pane>
                    <el-tab-pane label="PACKING SLIP " name="packing_slip">
                      <div v-if="view.packing_slip != ''">
                        <el-table
                          :data="view.packing_slip"
                          element-loading-text="Loading"
                          stripe
                          border
                          fit
                        >
                          <el-table-column
                            fixed
                            align="center"
                            label="Packing #"
                            prop="packing_id"
                          >
                            <template slot-scope="scope">
                              <el-tag slot="reference" size="mini"
                                ><a
                                  href="#"
                                  @click.prevent="
                                    viewPacking(scope.row.packing_id)
                                  "
                                  >{{
                                    scope.row.packing_no
                                      ? scope.row.packing_no
                                      : ""
                                  }}</a
                                ></el-tag
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Order Number"
                            align="center"
                            prop="order_number"
                          >
                            <template slot-scope="scope">
                              <span>{{ scope.row.order.order_number }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Total Weight"
                            align="center"
                            prop="total_weight"
                          >
                            <template slot-scope="scope">
                              <span>{{ scope.row.total_weight }} Kg</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Created Date"
                            align="center"
                            prop="created_at"
                          >
                            <template slot-scope="scope">
                              <span>{{
                                format_date(scope.row.created_at)
                              }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Ship Date"
                            align="center"
                            prop="ship_date"
                          >
                            <template slot-scope="scope">
                              <span>{{
                                format_date(scope.row.ship_date)
                              }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="BACK ORDERS" name="back_orders">
                      <div v-if="view.back_order != ''">
                        <el-table
                          :data="view.back_order"
                          element-loading-text="Loading"
                          stripe
                          border
                          fit
                        >
                          <el-table-column label="Back Order #" width="150">
                            <template slot-scope="scope">
                              <el-tag slot="reference" size="mini"
                                ><a
                                  href="#"
                                  @click.prevent="viewBackorder(scope.row.id)"
                                  >{{
                                    scope.row.order_number
                                      ? scope.row.order_number
                                      : ""
                                  }}</a
                                ></el-tag
                              >
                            </template>
                          </el-table-column>
                          <el-table-column label="Order #" width="120">
                            <template slot-scope="scope">
                              {{
                                scope.row.order
                                  ? scope.row.order.order_number
                                  : ""
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="BO Created"
                            prop="customer_name"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              {{
                                scope.row.back_order_created_by
                                  ? scope.row.back_order_created_by
                                  : ""
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Created Date"
                            prop="delivery_date"
                            width="120"
                          >
                            <template slot-scope="scope">
                              {{
                                scope.row.created_at | formatDate("DD MMM YYYY")
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label=" Order Status"
                            width="150"
                            prop="status"
                            align="center"
                          >
                            <template slot-scope="scope">
                              <span
                                v-if="scope.row.order_status == 'completed'"
                                class="active"
                                >Completed</span
                              >
                              <span
                                v-if="scope.row.order_status == 'processing'"
                                class="active"
                                >Processing</span
                              >
                              <span
                                v-if="
                                  scope.row.order_status ==
                                  'partially processed'
                                "
                                class="active"
                                >Partially Processed
                              </span>
                              <span
                                v-if="scope.row.order_status == 'backorder'"
                                class="red"
                                >BackOrder</span
                              >
                              <span
                                v-if="scope.row.order_status == 'cancelled'"
                                class="red"
                                >Cancel Order</span
                              >
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="RETURN ORDERS" name="return_order">
                      <div v-if="view.return_order != ''">
                        <el-table
                          :data="view.return_order"
                          element-loading-text="Loading"
                          stripe
                          border
                          fit
                        >
                          <el-table-column label="Return #" width="90">
                            <template slot-scope="scope">
                              <el-tag slot="reference" size="mini"
                                ><a
                                  href="#"
                                  @click.prevent="viewReturnorder(scope.row.id)"
                                  >{{ scope.row.id ? scope.row.id : "" }}</a
                                ></el-tag
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Return Amount Type"
                            width="150"
                          >
                            <template slot-scope="scope">
                              <div
                                v-if="
                                  scope.row.return_amount_type == 'credit_memo'
                                "
                              >
                                <span>Credit Memo</span>
                              </div>
                              <div
                                v-if="
                                  scope.row.return_amount_type ==
                                  'refund_online'
                                "
                              >
                                <span>Refund Online</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Return Taken By"
                            prop="return_taken_by"
                            width="150"
                          >
                            <template slot-scope="scope">
                              {{
                                scope.row.return_taken_by
                                  ? scope.row.return_taken_by
                                  : ""
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Return Reason"
                            prop="return_reason"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              {{
                                scope.row.return_reason
                                  ? scope.row.return_reason
                                  : ""
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Return Amount"
                            prop="order_taken_by"
                            width="120"
                          >
                            <template slot-scope="scope">
                              <span v-if="scope.row.total"
                                >${{
                                  scope.row.total ? scope.row.total : ""
                                }}</span
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Created Date"
                            prop="delivery_date"
                            width="100"
                          >
                            <template slot-scope="scope">
                              {{
                                scope.row.created_at | formatDate("DD MMM YYYY")
                              }}
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </form>
            <!--end: Settings Form-->
          </div>
        </div>
        <!--end: Settings Body-->
      </div>
    </div>
    <!--end: Settings-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import { getCorder, downloadPdf, deleteDocument } from "@/api/order";
import SendEmail from "@/components/SendEmail";
import deliveryDocument from "@/components/OrderDelivery/add_delivery_document";
import { globalEvents } from "@/utils/globalEvents";

export default {
  name: "view-order",
  components: {},
  data() {
    return {
      activeTab: "delivery_details",
      activeNames: 0,
      loading: false,
      img_url: process.env.VUE_APP_BASE_IMAGE_URL + "delivery/",
      view: [],
      viewitem: [],
      view_type: "orderConfirm",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "View Order" },
    ]);

    globalEvents.$on("adddocument", (type) => {
      this.fetchData(1);
      if (type) {
        this.fetchData(1);
      }
    });
  },

  created() {
    this.fetchData(1);
  },

  methods: {
    handleImageDownload(data, fileName) {
      if (data) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    deleteDocumentById(docId) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this Document?", {
          title: "Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            deleteDocument(docId).then((response) => {
              if (response.data.status) {
                this.$notify({
                  title: "Success",
                  type: "success",
                  message: response.data.message,
                });
                this.fetchData(1);
              }
            });
          } else {
            return false;
          }
        });
    },

    fetchData() {
      this.loading = true;
      getCorder(this.$route.params.order_id).then((response) => {
        this.view = response.data.data;
        this.loading = false;
      });
    },

    printWindow: function () {
      window.print();
    },

    viewDelivery(delivery_id) {
      this.$router.push({ path: "/delivery/view/" + delivery_id });
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    addCompleteDocument(id) {
      this.$modal.show(
        deliveryDocument,
        {
          order_id: parseInt(this.$route.params.order_id),
          deliveryId: id,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "700px",
        }
      );
    },

    viewPacking(packing_id) {
      this.$router.push({ path: "/packingslip/view/" + packing_id });
    },

    viewBackorder(backorder_id) {
      this.$router.push({ path: "/backorders/view/" + backorder_id });
    },

    viewReturnorder(return_id) {
      this.$router.push({ path: "/return/order/view/" + return_id });
    },

    viewCancel(cancel_id) {
      this.$router.push({ path: "/cancel/order/view/" + cancel_id });
    },

    handleActions(evt) {
      if (evt === 1) {
        this.printWindow();
      } else if (evt === 2) {
        this.loading = true;
        downloadPdf(this.$route.params.order_id, this.view_type)
          .then((response) => {
            //console.log(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "order.pdf");
            document.body.appendChild(link);
            link.click();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else if (evt === 3) {
        let customer = this.view.customer;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(
          SendEmail,
          { customer, type, order_id },
          {
            draggable: true,
            transition: "pop-out",
            height: "auto",
            clickToClose: false,
            adaptive: true,
            width: "500px",
          }
        );
      }
    },
  },
};
</script>

<style >
hr.invoice-line {
  border-top: 1px solid black;
  width: 100%;
}
.billing-adr,
.shipping-adr {
  font-size: 15px;
  background-color: #366092;
  color: #ffffff;
  padding: 3px;
  border: 1px solid #366092;
}
.invoice-header {
  background-color: #366092;
  color: #ffffff;
}
#website-table th,
#website-table td {
  border: 1px solid #366092;
  padding: 5px;
}
.tab-row-hover {
  border: 1px solid #366092;
}
.add-info {
  margin-bottom: 20px;
}
.default-logo img {
  width: 125px;
  margin-top: 30px;
  margin-left: 10px;
}
.text-end {
  text-align: end !important;
}

@media print {
  .print-section,
  .download-btn,
  .subheader,
  .action_btn,
  .action_dropdown {
    visibility: hidden;
    -webkit-print-color-adjust: exact;
  }
  .billing-adr,
  .shipping-adr {
    font-size: 15px;
    background-color: #366092;
    color: #ffffff;
    -webkit-print-color-adjust: exact;
  }

  .table th {
    background-color: #366092 !important;
    color: #ffffff !important;
    -webkit-print-color-adjust: exact;
  }
}
</style>